export const CREATE_DOSSIER_REQUEST = 'CREATE_DOSSIER_REQUEST';
export const CREATE_DOSSIER_REQUEST_SUCCESS = 'CREATE_DOSSIER_REQUEST_SUCCESS';
export const CREATE_DOSSIER_REQUEST_FAIL = 'CREATE_DOSSIER_REQUEST_FAIL';

export const DOSSIER_REQUEST = 'DOSSIER_REQUEST';
export const DOSSIER_REQUEST_SUCCESS = 'DOSSIER_REQUEST_SUCCSES';
export const DOSSIER_REQUEST_FAIL = 'DOSSIER_REQUEST_FAIL';

export const PENDING_DOSSIER_REQUEST = 'PENDING_DOSSIER_REQUEST';
export const PENDING_DOSSIER_REQUEST_SUCCESS =
	'PENDING_DOSSIER_REQUEST_SUCCSES';
export const PENDING_DOSSIER_REQUEST_FAIL = 'PENDING_DOSSIER_REQUEST_FAIL';


export const MY_DOCUMENTS_REQUEST = 'MY_DOCUMENTS_REQUEST';
export const MY_DOCUMENTS_REQUEST_SUCCESS = 'MY_DOCUMENTS_REQUEST_SUCCESS';
export const MY_DOCUMENTS_REQUEST_ERROR = 'MY_DOCUMENTS_REQUEST_ERROR';

export const UPDATE_DOSSIER_REQUEST = 'UPDATE_DOSSIER_REQUEST';
export const UPDATE_DOSSIER_REQUEST_SUCCESS = 'UPDATE_DOSSIER_REQUEST_SUCCSES';
export const UPDATE_DOSSIER_REQUEST_FAIL = 'UPDATE_DOSSIER_REQUEST_FAIL';

export const DOSSIER_HISTORY_REQUEST = 'DOSSIER_HISTORY_REQUEST';
export const DOSSIER_HISTORY_REQUEST_SUCCESS =
	'DOSSIER_HISTORY_REQUEST_SUCCSES';
export const DOSSIER_HISTORY_REQUEST_FAIL = 'DOSSIER_HISTORY_REQUEST_FAIL';

export const DOSSIER_COMMENT_REQUEST = 'DOSSIER_COMMENT_REQUEST';
export const DOSSIER_COMMENT_REQUEST_SUCCESS =
	'DOSSIER_COMMENT_REQUEST_SUCCSES';
export const DOSSIER_COMMENT_REQUEST_FAIL = 'DOSSIER_COMMENT_REQUEST_FAIL';

export const REJECT_DRAFT_REQUEST = 'REJECT_DRAFT_REQUEST';
export const REJECT_DRAFT_REQUEST_SUCCESS = 'REJECT_DRAFT_REQUEST_SUCCESS';
export const REJECT_DRAFT_REQUEST_ERROR = 'REJECT_DRAFT_REQUEST_ERROR';

export const APPROVE_DRAFT_REQUEST = 'APPROVE_DRAFT_REQUEST';
export const APPROVE_DRAFT_REQUEST_SUCCESS = 'APPROVE_DRAFT_REQUEST_SUCCESS';
export const APPROVE_DRAFT_REQUEST_ERROR = 'APPROVE_DRAFT_REQUEST_ERROR';

export const REJECT_BEFORE_SIGNATURE_REQUEST = 'REJECT_BEFORE_SIGNATURE_REQUEST';
export const REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS = 'REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS';
export const REJECT_BEFORE_SIGNATURE_REQUEST_ERROR = 'REJECT_BEFORE_SIGNATURE_REQUEST_ERROR';
