const style = (theme) => ({
    
    mainContainer:{
        width:'100%',
        height: '100%',
        background:'white',
        border:'1px solid #ccc',
        borderRadius:'3px',
        boxShadow:theme.customConstants.defaultBoxShadow

    },
    overflowingTitleBox:{
        borderRadius: "3px",
        border:'1px solid #ccc',
        background:theme.palette.primary.main,
        color: theme.palette.primary.textColorWithMainBackground,
        //backgroundColor: grayColor[0],
        padding: "15px",
        margin:"-20px 15px 0px",
        boxShadow:theme.customConstants.defaultBoxShadow
    },
    entitledBoxContents:{
        margin:'15px',
    },
    datagrid:{
        border:'none'
    },
});

export default style;