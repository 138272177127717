/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js';
import RTLNavbarLinks from 'components/Navbars/RTLNavbarLinks.js';

import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle.js';
import { useDispatch } from 'react-redux';
import { signout } from 'actions/userActions';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
	const classes = useStyles();
	let location = useLocation();
	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return location.pathname.indexOf(routeName) >= 0;
	}
	const { color, logo, image, logoText, routes } = props;
	const isLoginScreen = activeRoute('/login');

	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(signout());
	};

	var links = (
		<List className={classes.list}>
			{routes.map((prop, key) => {
				if (prop.hideOnSidebar) return undefined;
				var activePro = ' ';
				var listItemClasses;

				listItemClasses = classNames({
					[' ' + classes[color]]: activeRoute(prop.path),
				});

				return (
					<NavLink
						to={prop.path}
						className={activePro + classes.item}
						activeClassName='active'
						key={key}
					>
						<ListItem
							button
							className={classes.itemLink + listItemClasses}
						>
							{typeof prop.icon === 'string' ? (
								<Icon
									className={classNames(classes.itemIcon, {
										[classes.itemIconRTL]: props.rtlActive,
									})}
								>
									{prop.icon}
								</Icon>
							) : (
								<prop.icon
									className={classNames(classes.itemIcon, {
										[classes.itemIconRTL]: props.rtlActive,
									})}
								/>
							)}
							<ListItemText
								primary={
									props.rtlActive ? prop.rtlName : prop.name
								}
								className={classNames(classes.itemText, {
									[classes.itemTextRTL]: props.rtlActive,
								})}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				);
			})}
			<NavLink
				onClick={handleLogout}
				to={'/login'}
				className={classes.item}
			>
				<ListItem button className={classes.itemLink}>
					<Icon
						className={classNames(classes.itemIcon, {
							[classes.itemIconRTL]: props.rtlActive,
						})}
					>
						<ExitToAppIcon />
					</Icon>
					<ListItemText
						primary={'Cerrar Sesion'}
						className={classNames(classes.itemText, {
							[classes.itemTextRTL]: props.rtlActive,
						})}
						disableTypography={true}
					/>
				</ListItem>
			</NavLink>
		</List>
	);
	var brand = (
		<div className={classes.logo}>
			<a
				href={'https://' + window.location.hostname + '/documento'}
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive,
				})}
				target='_blank'
			>
				<img src={logo} alt='logo' className={classes.img} />
				{/* <div className={classes.logoImage}></div> */}
				{/* {logoText} */}
			</a>
		</div>
	);
	return (
		<div>
			{!isLoginScreen && (
				<div>
					<Hidden mdUp implementation='css'>
						<Drawer
							variant='temporary'
							anchor={props.rtlActive ? 'left' : 'right'}
							open={props.open}
							classes={{
								paper: classNames(classes.drawerPaper, {
									[classes.drawerPaperRTL]: props.rtlActive,
								}),
							}}
							onClose={props.handleDrawerToggle}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
						>
							{/*{brand} */}
							<div className={classes.logo}>
								<a
									href={
										'https://' +
										window.location.hostname +
										'?ref=mdr-sidebar'
									}
									className={classNames(classes.logoLink, {
										[classes.logoLinkRTL]: props.rtlActive,
									})}
									target='_blank'
								>
									<div className={classes.logoImage}>
										<img
											src={logo}
											alt='logo'
											className={classes.img}
										/>
									</div>
									{logoText}
								</a>
							</div>
							<div className={classes.sidebarWrapper}>
								{props.rtlActive ? (
									<RTLNavbarLinks />
								) : (
									<AdminNavbarLinks />
								)}
								{links}
							</div>
							{image !== undefined ? (
								<div
									className={classes.background}
									style={{
										backgroundImage: 'url(' + image + ')',
									}}
								/>
							) : null}
						</Drawer>
					</Hidden>
					<Hidden smDown implementation='css'>
						<Drawer
							anchor={props.rtlActive ? 'right' : 'left'}
							variant='permanent'
							open
							classes={{
								paper: classNames(classes.drawerPaper, {
									[classes.drawerPaperRTL]: props.rtlActive,
								}),
							}}
						>
							{brand}
							<div className={classes.sidebarWrapper}>
								{links}
							</div>
							{image !== undefined ? (
								<div
									className={classes.background}
									style={{
										backgroundImage: 'url(' + image + ')',
									}}
								/>
							) : null}
						</Drawer>
					</Hidden>
				</div>
			)}
		</div>
	);
}

Sidebar.propTypes = {
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
	logo: PropTypes.string,
	image: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
};
