//import commonStyles from '../components/formsRelated'; didnt work as expected

const style = (theme) => ({
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
		justifyContent: 'center',
	},
	leftRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
	},
	rightRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
	},
	subHeadline: {
		color: '#9c9c9c',
	},
	id: {
		marginRight: '10px',
	},
	clickable: {
		display: 'flex',
		width: '25px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	contentCard: {
		marginTop: '1.5%',
		padding: '1.5%',
		backgroundColor: '#fff',
		border: '2px solid black',
	},
	uploadSection: {
		paddingLeft: '2%',
		width: '100%',
	},
	note: {
		backgroundColor: '#f2f2f2',
		padding: '5px',
		marginRight: '5px',
	},
	borderRight: {
		[theme.breakpoints.up('md')]: {
			borderRight: '2px solid black',
		},
	},
	borderTop: {
		borderTop: '2px solid black',
	},
	buttonPrimary: {
		backgroundColor: '#3B6FB5',
		color: '#fff',
		marginTop: '20px',
		marginLeft: '15px',
	},
	buttonDanger: {
		backgroundColor: '#f44336',
		color: '#fff',
		marginTop: '20px',
		marginLeft: '15px',
		'&:hover, &:focus': {
			backgroundColor: '#c43a3a',
		},
	},

	buttonSecondary: {
		backgroundColor: '#f2f2f2',
		color: '#000',
		marginTop: '20px',
		marginLeft: '15px',
	},
	buttonSuccess: {
		backgroundColor: '#4caf50',
		color: '#fff',
		marginTop: '20px',
		marginLeft: '15px',
	},
	loader: {
		backgroundColor: '#fff',
		color: '#000',
		marginTop: '20px',
		marginLeft: '15px',
	},
	textBreak: {
		wordBreak: 'break-word',
	},
	modal: {
		'& > div >div': {
			[theme.breakpoints.up('sm')]: {
				maxWidth: '65vw',
				width: '100%',
				minWidth: '500px',
				height: '100%',
				maxHeight: '50vh',
				minHeight: '300px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				maxWidth: '100vw',
				height: '100%',
				maxHeight: '70vh',
				minHeight: '300px',
			},
		},
	},
	textAreaContainer: {
		overflowY: 'initial',
	},
	textArea: {
		width: '100%',
		height: '100% !important',
		resize: 'none',
		boxSizing: 'border-box',
		'-webkit-box-sizing': 'border-box',
		'-moz-box-sizing': 'border-box',
	},
	modalActions: {
		padding: '8px 24px',
	},
	modalTitle: {
		'& > h2': {
			fontSize: '1.5rem',
		},
	},
	littleRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		margin: '0.9rem 0',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		width: '100%',
	},
	alignStart: {
		alignSelf: 'flex-start',
	},
	centered: {
		display: 'flex',
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			justifyContent: 'flex-end',
		},
	},
	signContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		maxHeight: '300px',
		overflowY: 'auto',
		width: '100%',
		paddingLeft: '10px',
	},
	signLabel: {
		color: 'black',
	},
});

export default style;
