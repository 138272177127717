import React, { useState, useEffect } from 'react';

import {
	Worker,
	Viewer,
	SpecialZoomLevel,
	LayerRenderStatus,
} from '@react-pdf-viewer/core';
import { SearchIcon } from '@react-pdf-viewer/search';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import CustomToolbar from './CustomToolbar/CustomToolbar';

import styles from './GLIFOSPdfViewer.module.css';
import Loader from './Loader/Loader';
import SearchPlugin from './SearchPlugin/SearchPlugin';
import { useSelector } from 'react-redux';

const WORKER_URL =
	'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

export function GLIFOSPDFViewer({ fileUrl, signPosition }) {
	const [theme, setTheme] = useState('dark');
	const [pdfCanvas, setPdfCanvas] = useState(null);
	const { userInfo } = useSelector((state) => state.userSignin);
	const handleSwitchTheme = (new_theme) => {
		setTheme(new_theme);
	};
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		sidebarTabs: (defaultTabs) => [
			{
				content: (
					<SearchPlugin
						searchPluginInstance={
							defaultLayoutPluginInstance.toolbarPluginInstance
								.searchPluginInstance
						}
						theme={theme}
					/>
				),
				icon: <SearchIcon />,
				title: 'Search',
			},
			defaultTabs[0],
		],
		renderToolbar: CustomToolbar,
		toolbarPlugin: {
			zoomPlugin: {
				enableShortcuts: true,
			},
		},
	});
	const customCanvasPlugin = () => {
		const onCanvasLayerRender = (e) => {
			// Return if the canvas isn't rendered completely
			if (e.status !== LayerRenderStatus.DidRender) {
				return;
			}
			//console.log(e);

			// `e.ele` is the canvas element
			const canvas = e.ele;
			//pdfCanvasRef.current = { canvas, e };

			setPdfCanvas({ canvas, e });

			/* const ctx = canvas.getContext('2d');
			const centerX = canvas.width / 2;
			const centerY = canvas.height / 2;

			const fonts = ctx.font.split(' ');
			const fontSize = parseInt(fonts[0], 10);

			ctx.textAlign = 'center';
			ctx.font = `${fontSize * e.scale * 4}px ${fonts[1]}`;

			ctx.fillStyle = '#CCC';
			ctx.fillText(message, centerX, 100); */

			/* if (signPositions.length > 0) {
				signPositions.forEach((sign) => {
					console.log(sign);
					const client = 'Juan Pablo';

					const ctx = canvas.getContext('2d');
					console.log(ctx);
					ctx.beginPath();
					ctx.rect(sign.x, sign.y, 100, 100);
				});
			} */
			if (signPosition?.signature_info) {
				const pdfContainer = document.querySelector(
					`.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div > div.rpv-core__inner-page-container > div[aria-label="Page ${
						+signPosition.signature_info.page + 1
					}"]`
				)?.firstChild;
				if (!pdfContainer) return;
				const sign = signPosition.signature_info;

				const page = sign.page;
				console.log(page);
				const newChild = document.createElement('div');
				newChild.id = `sign-${sign.id}`;
				newChild.style.border = '2px solid #3b6fb5';
				newChild.style.textAlign = 'center';
				newChild.style.fontSize = 'calc(var(--scale-factor) * 1rem)';
				newChild.style.backgroundColor = 'rgb(87, 148, 229, 0.5)';
				newChild.style.display = 'flex';
				newChild.style.justifyContent = 'center';
				newChild.style.alignItems = 'center';
				newChild.style.position = 'absolute';
				newChild.style.fontWeight = 'bold';
				newChild.style.color = 'rgb(0,0,0,0.8)';
				newChild.style.left = `calc(var(--scale-factor) * ${sign.x}px)`;
				newChild.style.top = `calc(var(--scale-factor) * ${sign.y}px)`;
				newChild.style.width = `calc(var(--scale-factor) * ${sign.width}px)`;
				newChild.style.height = `calc(var(--scale-factor) * ${sign.height}px)`;
				newChild.innerText = userInfo.name;
				newChild.style.zIndex = 10;
				pdfContainer.appendChild(newChild);
			}
		};

		return {
			onCanvasLayerRender,
		};
	};
	const customCanvasPluginInstance = customCanvasPlugin();
	return (
		<Worker workerUrl={WORKER_URL}>
			<div className={`${styles.cnt_viewer}`}>
				<Viewer
					onSwitchTheme={handleSwitchTheme}
					fileUrl={fileUrl}
					renderLoader={Loader}
					plugins={[
						defaultLayoutPluginInstance,
						customCanvasPluginInstance,
					]}
					defaultScale={1}
					renderError={ErrorMessage}
					theme={{
						theme: 'dark',
					}}
				/>
			</div>
		</Worker>
	);
}
