import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import FirmaRevision from 'components/FirmaRevision/FirmaRevision';
import FirmaUpload from 'components/FirmaUpload/FirmaUpload';
import { prepare } from '../../actions/signActions';
import { getDossier } from '../../actions/dossierActions';
import Axios from '../../services/Api.js';
import FirmaSelection from 'components/FirmaSelection/FirmaSelection';

const useStyles = makeStyles(styles);

export default function FirmaCliente(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { id } = useParams();

	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;

	const dossierState = useSelector((state) => state.dossier);
	const [dossier, setDossier] = useState(null);

	const signRequest = useSelector((state) => state.signRequest);

	const [step, setStep] = useState(1);
	const [validationToken, setValidationToken] = useState(null);

	const goBack = () => {
		props.history.push('/documento');
	};

	const backStep = () => {
		if (step > 1) setStep(step - 1);

		if (step === 1) props.history.push('/documento');
	};

	const prepareSignatureRequest = async () => {
		dispatch(prepare(dossier.documentData.id));
	};

	useEffect(() => {
		Axios.GetVerificationToken()
			.then((res) => {
				if (!res.data.success)
					throw new Error(
						'No se pudo obtener el token de verificación'
					);
				setValidationToken(res.data.token);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (signRequest.isSetup && step == 1) setStep(step + 1);
	}, [signRequest]);

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		dispatch(getDossier(id));
	}, []);

	useEffect(() => {
		if (Object.keys(dossierState.data).length !== 0) {
			const firmante = dossierState?.data[0]?.relations?.sections.find(
				(section) =>
					section.model.type === 4 &&
					+section.model.owner === +userInfo.id
			);
			const temp = {
				dossierId: dossierState?.data[0]?.model?.id,
				dossierData:
					dossierState?.data[0]?.relations?.sections[0]?.model,
				documentData:
					dossierState?.data[0]?.relations?.sections[0]?.relations
						?.documents[0]?.model,
				dossierMeeting:
					dossierState?.data[0]?.relations?.dossierMeeting[0],
				signPosition: JSON?.parse(firmante?.model?.data || '{}')
					?.transaction,
				needsVerification: dossierState?.data[0]?.relations?.sections
					?.find((section) => +section?.model?.type === 6)
					?.relations?.subSections?.some(
						(subsection) =>
							+subsection.model.weight >= 10 &&
							+subsection.model.status < 100 &&
							+subsection.model.owner === +userInfo.id
					),
			};
			console.log(temp);
			console.log(dossierState);
			setDossier(temp);
		} // if
	}, [dossierState]);

	return (
		<div>
			{dossier && (
				<div>
					<Grid container className={classes.container}>
						<Grid item xs={6} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={1} className={classes.row}>
									<div
										className={classes.clickable}
										onClick={goBack}
									>
										<ArrowBackIcon />
									</div>
								</Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.id}
									>
										#{dossier.dossierData?.id}
									</Typography>
									<Typography variant='h3'>
										{dossier.dossierData?.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid container className={classes.container}>
								<Grid
									item
									xs={1}
									className={classes.row}
								></Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										Firma de documento
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={12} className={classes.rightRow}>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										Creador del documento
									</Typography>
								</Grid>
								<Grid item xs={12} className={classes.rightRow}>
									{/* <Typography variant="h3" className={classes.id}>
                    #{fakeCreator.id}
                  </Typography> */}
									<Typography variant='h3'>
										{dossier.dossierData?.created_by}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div className={classes.contentCard}>
						{step == 1 && (
							<FirmaRevision
								dossierId={dossier.dossierId}
								document={dossier.documentData}
								dossier={dossier.dossierData}
								dossierMeeting={dossier.dossierMeeting}
								signPosition={dossier.signPosition}
								nextStep={prepareSignatureRequest}
								needsVerification={dossier.needsVerification}
								verificationToken={validationToken}
							/>
						)}
						{step == 2 && (
							<FirmaSelection
								backStep={backStep}
								signPosition={dossier.signPosition}
							/>
						)}
						{/* <FirmaUpload
							/> */}
					</div>
				</div>
			)}
		</div>
	);
}
