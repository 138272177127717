import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Axios from 'services/Api';
import { CircularProgress } from '@material-ui/core';
import { WebVerification } from 'vdid-sdk-web';
import axios from 'axios';
import ApiURLs from 'config/ApiURLs';
// import logo from '../../assets/img/logo.svg';

const styles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100vh',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '50%',
		height: '40vh',
		backgroundColor: '#ffffff',
		border: '2px solid #000000',
		flexWrap: 'wrap',
		padding: '2rem',

		[theme.breakpoints.down('md')]: {
			width: '60%',
			height: '50vh',
		},
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '60vh',
		},
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			height: '80vh',
		},
	},
	alertError: {
		color: 'red',
		border: '1px solid red',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffebee',
		borderRadius: '5px',
		width: '100%',
		alignSelf: 'center',
		position: 'relative',
	},
	closeIconAlert: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		justifyContent: 'center',
		width: '20px',
		'&:hover': {
			color: 'black',
			borderRadius: '50%',
			aspectRatio: '1/1',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
});

const useStyles = makeStyles(styles);

export default function VerificarCliente(props) {
	const classes = useStyles();
	const { token, dossier } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState({
		error: false,
		message: '',
	});

	const validateUser = async () => {
		try {
			setIsLoading(true);
			const { data } = await axios.post(
				`${ApiURLs.baseURL}user/create-verification`,
				{
					dossier,
					token,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (Boolean(data?.error)) {
				throw new Error(data?.message ?? data?.error_log ?? 'Error');
			}
			if (Boolean(data.success)) {
				if(data?.redirect) window.location.href= data.redirect;
				const uuid = data.verification;
				const vdid = new WebVerification(
					`${process.env.REACT_APP_SUMA_PUBLIC_API_KEY}`
				);
				vdid.verifyIdentity(uuid, {
					method: 'redirect',
				});
			}
		} catch (error) {
			setIsError({
				error: true,
				message: error.message,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<main className={classes.mainContainer}>
			<section className={classes.cardContainer}>
				<Typography variant='h2' className={classes.title}>
					{!isError.error
						? 'Información pendiente de Validar'
						: 'Hubo un error en la verificación'}
				</Typography>
				<div>
					<p>
						{`Su información debe ser verificada, haga click en el botón para iniciar el proceso de verificación`}
					</p>
					{isError.error && (
						<div className={classes.alertError}>
							{isError.message ||
								'Ocurrio un error, por favor intentelo más tarde'}
							<span
								className={classes.closeIconAlert}
								onClick={() => {
									setIsError({
										error: false,
										message: '',
									});
								}}
							>
								X
							</span>
						</div>
					)}
				</div>
				<Button
					variant='contained'
					color='primary'
					className={classes.buttonPrimary}
					onClick={validateUser}
					disabled={isLoading || isError.error}
					endIcon={isLoading ? <CircularProgress size={20} /> : null}
				>
					Verificar Datos
				</Button>
				{/* <Grid item xs={12}>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} className={classes.sectionLeft}>
							<div className={classes.buttonSpace}>
								<Button
									variant='contained'
									color='primary'
									className={classes.buttonPrimary}
								>
									Verificar Datos
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid> */}
			</section>
		</main>
	);
}
