import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Axios from 'services/Api.js';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';

import Firma from 'components/Firma/Firma';

import { finish } from '../../actions/signActions';
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function FirmaSelection(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const signRequest = useSelector((state) => state.signRequest);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo } = userSignin;

	const [password, setPassword] = useState('');
	const [signID, setSignID] = useState(null);
	const [signList, setSignList] = useState([]);

	useEffect(() => {
		Axios.GetSignatures(userInfo.id)
			.then((res) => {
				if (!res.data.success) throw new Error(res.data.message);
				setSignList(res.data.signatures);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleFirmar = () => {
		if (password === '' || password === undefined) {
			Swal.fire({
				icon: 'error',
				title: 'Campos requeridos',
				text: 'Por favor ingrese todos los campos',
			});
			return;
		}
		const sign = props.signPosition.signature_info;
		const page = sign.page;
		const signature = {
			x: sign.x,
			y: sign.y,
			width: sign.width,
			height: sign.height,
			page: page,
		};
		//console.log('signature', signature);
		dispatch(
			finish(signRequest.signInfo.request_id, signID, password, signature)
		);
	};

	const handleChange = (event) => {
		console.log(event.target.value);
		setSignID(event.target.value);
	};

	return (
		<Grid container className={classes.container}>
			<Grid
				item
				xs={12}
				md={3}
				className={classNames(classes.column, classes.borderRight)}
			>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.column}>
						<div className={classes.note}>
							<b>Seleccione firma</b> La firma utilizará los
							archivos correspondientes a tu e.firma, recuerda que
							esta acción no se puede revertir.
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={9} className={classes.column}>
				<div className={classes.uploadSection}>
					<Grid container className={classes.container}>
						<Grid item xs={12} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={12} className={classes.column}>
									<Typography
										variant='h4'
										className={classes.littleRow}
									>
										1. Selecciona firma a utilizar
									</Typography>
									<div className={classes.signContainer}>
										<RadioGroup
											aria-label='sign'
											name='sign'
											value={+signID}
											onChange={handleChange}
										>
											{signList.map((sign) => (
												<FormControlLabel
													key={sign.uuid}
													value={+sign.id}
													control={<Radio />}
													label={sign.name}
													className={
														classes.signLabel
													}
												/>
											))}
										</RadioGroup>
									</div>
								</Grid>
								<Grid item xs={12} className={classes.column}>
									<Typography
										variant='h4'
										className={classes.littleRow}
									>
										2. Ingrese la contraseña de la firma
									</Typography>
									{signID && (
										<TextField
											fullWidth
											value={props.password}
											name='password'
											type='password'
											onChange={(event) =>
												setPassword(event.target.value)
											}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.rightRow}>
						<Button
							variant='contained'
							color='secondary'
							className={classes.buttonSecondary}
							onClick={props.backStep}
						>
							Volver atrás
						</Button>
						<Button
							variant='contained'
							color='success'
							className={classes.buttonSuccess}
							onClick={handleFirmar}
							disabled={signRequest.loading}
						>
							Aceptar y firmar
						</Button>
						{signRequest.loading && (
							<Button variant='text' className={classes.loader}>
								<i className='fa fa-spinner fa-spin'></i>
								&nbsp;Cargando ...
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
