import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';

import ApiURLs from '../../config/ApiURLs.js';
import { rejectBeforeSignature } from '../../actions/dossierActions';
import PDFViewer from 'components/PDFViewer/PDFViewer';

import Swal from 'sweetalert2';

const useStyles = makeStyles(styles);

export default function FirmaRevision(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const signRequest = useSelector((state) => state.signRequest);
	//const [file, setFile] = useState(fakeVersions[0]);

	const fileUrl = ApiURLs.baseURL + 'file/get/' + props?.document?.file;

	const handleRejectDocument = () => {
		Swal.fire({
			title: 'Rechazar documento',
			html: `<textarea id="comment" class="swal2-input" placeholder="Comentario"/>`,
			confirmButtonText: 'Rechazar documento',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			focusConfirm: false,
			preConfirm: () => {
				const comment = Swal.getPopup().querySelector('#comment').value;
				if (!comment) {
					Swal.showValidationMessage(
						`Por favor ingrese un comentario`
					);
				}
				return { comment: comment };
			},
		}).then((result) => {
			if (result.value)
				dispatch(
					rejectBeforeSignature(
						props.dossier.id,
						result.value.comment
					)
				);
		});
	};

	return (
		<Grid container className={classes.container}>
			<Grid
				item
				xs={12}
				md={2}
				className={classNames(classes.column, classes.borderRight)}
			>
				<Grid container className={classes.container}>
					{!props.needsVerification && (
						<Grid item xs={12} className={classes.column}>
							<div className={classes.note}>
								Revisa el documento y cuando estés listo,
								procede al siguiente paso para firmar
							</div>
							{props.dossierMeeting?.model?.join_url && (
								<Button
									variant='contained'
									color='primary'
									onClick={() =>
										window.open(
											props.dossierMeeting?.model
												?.join_url,
											'_blank'
										)
									}
									className={classes.button}
									style={{ marginTop: '5px' }}
								>
									Unirse a Videollamada
								</Button>
							)}
						</Grid>
					)}
					{props.needsVerification && (
						<Grid item xs={12} className={classes.column}>
							<div className={classes.note}>
								Puede realizar la verificación de su documento
								dándole click al botón de abajo
							</div>
							{props.verificationToken && (
								<Button
									variant='contained'
									color='primary'
									onClick={() =>
										window.open(
											`/cliente/verificar-datos/${props.dossierId}/${props.verificationToken}`,
											'_blank'
										)
									}
									className={classes.button}
									style={{ marginTop: '5px' }}
								>
									Validar datos
								</Button>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} md={10} className={classes.column}>
				<PDFViewer url={fileUrl} signPosition={props.signPosition} />
				{!props.needsVerification && props.signPosition && (
					<Grid container className={classes.container}>
						<Grid item xs={12} className={classes.rightRow}>
							<Button
								variant='contained'
								color='primary'
								className={classes.buttonDanger}
								onClick={handleRejectDocument}
							>
								Rechazar documento
							</Button>
							<Button
								variant='contained'
								color='primary'
								className={classes.buttonPrimary}
								onClick={props.nextStep}
								disabled={signRequest.loading}
							>
								Firmar documento
							</Button>
							{signRequest.loading && (
								<Button
									variant='text'
									className={classes.loader}
								>
									<i className='fa fa-spinner fa-spin'></i>
									&nbsp;Cargando ...
								</Button>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}
