import {
	drawerWidth,
	transition,
	container,
	backgroundColor,
} from 'assets/jss/material-dashboard-react.js';

const style = (theme) => ({
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
		backgroundColor: backgroundColor,
	},
	header: {
		minHeight: '50px',
		backgroundColor: '#fff',
		paddingTop: '10px',
		paddingBottom: '10px',
		boxShadow:
			'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
	},
	content: {
		height: 'calc(100vh - 123px)',
	},
	contentCard: {
		marginTop: '1.5%',
		marginLeft: '5%',
		marginRight: '5%',
		padding: '1.5%',
		backgroundColor: '#fff',
		border: '2px solid black',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		marginBottom: '20px',
	},
	sectionLeft: {
		paddingTop: '1%',
		paddingBottom: '1%',
		paddingRight: '2%',
		/* [theme.breakpoints.up("md")]: {
      borderRight: "2px solid black",
    }, */
	},
	sectionRight: {
		paddingTop: '1%',
		paddingBottom: '1%',
		paddingLeft: '2%',
	},
	title: {
		marginBottom: '20px',
	},
	buttonSpace: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-end',
	},
	buttonPrimary: {
		backgroundColor: '#1866db',
		color: '#fff',
		marginTop: '20px',
		marginLeft: '15px',
	},
	buttonSuccess: {
		backgroundColor: '#4caf50',
		color: '#fff',
		marginTop: '20px',
		marginLeft: '15px',
	},
});

export default style;
