import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

import logo from 'assets/img/logo.svg';

let ps;

const switchRoutes = (
	<Switch>
		{routes.map((prop, key) => {
			return (
				<Route path={prop.path} exact component={prop.component} key={key} />
			);
		})}
		<Redirect from='/' to='/documento' />
	</Switch>
);

const findRole = (userInfo, roles = {}) => {
	let valid = false;
	userInfo &&
		userInfo?.roles.forEach((role) => {
			valid = roles.hasProperty(role);
			if (valid) return;
			/* roles.forEach((routeRole) => {
				if (routeRole.toLowerCase() === role.toLowerCase())
					valid = true;
			}); */
		});
	return valid;
};

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo } = userSignin;
	// styles
	const classes = useStyles();
	// ref to help us initialize PerfectScrollbar on windows devices
	const mainPanel = React.createRef();
	// states and functions
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getRoute = () => {
		return window.location.pathname !== '/admin/maps';
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};
	// initialize and destroy the PerfectScrollbar plugin
	React.useEffect(() => {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = 'hidden';
		}
		window.addEventListener('resize', resizeFunction);
		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf('Win') > -1) {
				ps.destroy();
			}
			window.removeEventListener('resize', resizeFunction);
		};
	}, [mainPanel]);

	React.useEffect(() => {
		if (!userInfo) return rest.history.push('/login');
		//else return rest.history.push('/documento');
	}, []);

	return (
		<div className={classes.wrapper}>
			<Sidebar
				routes={routes}
				logoText={'Firma Electrónica App'}
				logo={logo}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={'purple'}
				{...rest}
			/>
			<div className={classes.mainPanel} ref={mainPanel}>
				<Navbar
					routes={routes}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>

				<div className={classes.content}>
					<div className={classes.container}>{switchRoutes}</div>
				</div>
			</div>
		</div>
	);
}
