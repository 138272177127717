//import commonStyles from '../components/formsRelated'; didnt work as expected

const style = (theme) => ({
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		height: 'auto',
	},
	title: {
		display: 'flex',
		height: '2.5rem',
		alignItems: 'center',
		marginBottom: '1rem',
	},
	title2: {
		display: 'flex',
		height: '2.5rem',
		alignItems: 'center',
		marginBottom: '0.5rem',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		minHeight: '2.5rem',
		alignItems: 'center',
		marginBottom: '1rem',
	},
	row2: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		minHeight: '2.5rem',
		alignItems: 'center',
		margin: '15px 0',
	},
	littleRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		margin: '0.9rem 0',
	},
	alignStart: {
		alignSelf: 'flex-start',
	},
	centered: {
		display: 'flex',
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			justifyContent: 'flex-end',
		},
	},
	button: {
		marginLeft: '15px',
	},
	formControl: {
		'& span': {
			color: 'black',
		},
	},
	clientRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		height: '3rem',
		marginBottom: '15px',
		alignItems: 'center',
		backgroundColor: '#e0e0e0',
	},
	clientID: {
		marginRight: '10px',
		fontWeight: 'bold',
		paddingLeft: '5px',
	},
	clientName: {
		marginRight: '10px',
		paddingLeft: '5px',
	},
	removeClient: {
		display: 'flex',
		alignSelf: 'center',
		marginLeft: 'auto',
		marginRight: '15px',
		alignContent: 'flex-end !important',
		cursor: 'pointer',
	},
	formControl: {
		width: '100%',
	},
	fileContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		padding: '20px',
		border: '1px solid black',
		margin: '10px 0',
	},
	fileIcon: {
		marginRight: '15px',
	},
	fileName: {
		marginRight: 'auto',
	},
	retry: {},
});

export default style;
