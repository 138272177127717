import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDispatch, useSelector } from 'react-redux';
import {
	getDossier,
	rejectDraft,
	approveDraft,
} from '../../actions/dossierActions';
import { useParams } from 'react-router-dom';

import ApiURLs from '../../config/ApiURLs.js';
import PDFViewer from 'components/PDFViewer/PDFViewer';

import { TextareaAutosize } from '@material-ui/core';
import { WORD_MIME_TYPES } from 'utils/constants';

const useStyles = makeStyles(styles);

export default function BorradorCliente(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [value, setValue] = useState('');
	const [status, setStatus] = useState(0);

	const { id } = useParams();

	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo } = userSignin;

	const dossierState = useSelector((state) => state.dossier);
	const [dossier, setDossier] = useState(null);

	const [fileUrl, setFileUrl] = useState('');

	const goBack = () => {
		props.history.push('/documento');
	};

	const handleRejectDraft = () => {
		setShowModal(true);
	};

	const handleApproveDraft = async () => {
		dispatch(approveDraft(id));
	};

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		dispatch(getDossier(id));
	}, []);

	const getStatusText = (status) => {
		if (status >= 2001 && status <= 2010) return 'Carga del borrador';
		if (status >= 2011 && status <= 2040) return 'Revision del borrador';
		if (status >= 2041 && status <= 2068)
			return (
				<span>
					Revisión del borrador <br />{' '}
					<span style={{ color: 'red' }}>(Rechazado)</span>
				</span>
			);
		if (status >= 2069 && status <= 2070)
			return (
				<span>
					Revisión del borrador <br />{' '}
					<span style={{ color: 'green' }}>(Aprovado)</span>
				</span>
			);
		if (status >= 2071 && status <= 2130) return 'Firma del documento';
		if (status >= 2131 && status <= 2200) return 'Autorización preventiva';
		if (status >= 2201 && status <= 2299) return 'Autorización Definitiva';
		if (status >= 2300 && status <= 2399) return 'Finalizado Archivado';
		return 'Revisión del borrador';
	};

	useEffect(() => {
		if (Object.keys(dossierState.data).length !== 0) {
			console.log(dossierState);
			const file_data =
				dossierState.data[0]?.relations?.sections?.[0]?.relations
					?.documents?.[0]?.relations?._File0?.[0]?.model;
			console.log(file_data);
			const temp = {
				dossierData:
					dossierState?.data[0]?.relations?.sections[0]?.model,
				documentData:
					dossierState?.data[0]?.relations?.sections[0]?.relations
						?.documents[0]?.model,
				status: dossierState?.data[0]?.model?.status,

				file: {
					id: dossierState?.data[0]?.relations?.sections[0]?.relations
						?.documents[0]?.model?.file,
					isWordFile: WORD_MIME_TYPES.includes(file_data?.mime),
				},
			};
			setFileUrl(ApiURLs.baseURL + 'file/get/' + temp.documentData?.file);
			setDossier(temp);
		} // if
	}, [dossierState]);

	const sendComment = () => {
		setShowModal(false);
		dispatch(rejectDraft(id, value, goBack));
	};

	return (
		<div>
			{dossier && (
				<div>
					<Grid container className={classes.container}>
						<Grid item xs={12} md={6} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={1} className={classes.row}>
									<div
										className={classes.clickable}
										onClick={goBack}
									>
										<ArrowBackIcon />
									</div>
								</Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.id}
									>
										#{id}
									</Typography>
									<Typography variant='h3'>
										{dossier.dossierData?.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid container className={classes.container}>
								<Grid
									item
									xs={1}
									className={classes.row}
								></Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										{getStatusText(dossier.status)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={6} xs={12} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid
									item
									xs={12}
									className={`${classes.rightRow} ${classes.textBreak}`}
								>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										Creador del documento
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									className={`${classes.rightRow} ${classes.textBreak}`}
								>
									<Typography variant='h3'>
										{dossier.dossierData?.created_by}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div className={classes.contentCard}>
						<Grid container className={classes.container}>
							<Grid item xs={12} className={classes.column}>
								<PDFViewer url={fileUrl} file={dossier.file} />
								<Grid container className={classes.container}>
									<Grid
										item
										xs={12}
										className={classes.rightRow}
									>
										{dossier.status >= 2011 &&
											dossier.status < 2069 && (
												<Button
													variant='contained'
													color='primary'
													className={
														classes.buttonDanger
													}
													onClick={handleRejectDraft}
												>
													{dossier.status >= 2011 &&
													dossier.status < 2021
														? 'Solicitar revisión'
														: 'Agregar comentario'}
												</Button>
											)}
										{dossier.status < 2021 &&
											dossier.status >= 2011 && (
												<Button
													variant='contained'
													color='primary'
													className={
														classes.buttonPrimary
													}
													onClick={handleApproveDraft}
												>
													Aprobar borrador
												</Button>
											)}
										{/* {signRequest.loading && (
                      <Button variant="text" className={classes.loader}>
                        <i className="fa fa-spinner fa-spin"></i>&nbsp;Cargando
                        ...
                      </Button>
                    )} */}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			)}
			<Dialog
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby='form-dialog-title'
				className={classes.modal}
			>
				<DialogTitle
					id='form-dialog-title'
					style={{ fontSize: '1.5rem' }}
					className={classes.modalTitle}
				>
					Solicitar revisión
				</DialogTitle>
				<DialogContent className={classes.textAreaContainer}>
					<TextareaAutosize
						aria-label='Ingrese su comentario'
						placeholder='Ingrese su comentario'
						className={classes.textArea}
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
				</DialogContent>
				<DialogActions className={classes.modalActions}>
					<Button
						onClick={() => setShowModal(false)}
						color='secondary'
						variant='contained'
					>
						Cancelar
					</Button>
					<Button
						onClick={sendComment}
						color='primary'
						variant='contained'
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
