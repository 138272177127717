import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { userClientsReducer, userSigninReducer } from './reducers/userReducer';
import { checkInReducer } from './reducers/checkInReducer';
import {
	documentReducer,
	documentTypeReducer,
} from './reducers/documentReducer';
import { signReducer } from './reducers/signReducer';
import { createDossierReducer } from 'reducers/dossierReducer';
import { getPendingDossierReducer } from 'reducers/dossierReducer';
import { getDossierReducer } from 'reducers/dossierReducer';
import { updateDossierReducer } from 'reducers/dossierReducer';
import {
	DossierHistoryReducer,
	getMyDocumentsReducer,
	rejectDrafReducer,
	approveDrafReducer,
	rejectBeforeSignatureReducer,
} from 'reducers/dossierReducer';

function getInitialUserInfoCheck() {
	let userLocal = localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null;
	if (null != userLocal) {
		/* if (userLocal.session.willExpire) {
            const currentTime = new Date();
            const expiryTime = new Date(userLocal.session.willExpire);
            if (currentTime.valueOf() > expiryTime.valueOf()) {
                localStorage.removeItem('userInfo');
                userLocal = null;
            }
        } */
		if (userLocal.access_token) {
		} else {
			localStorage.removeItem('userInfo');
			userLocal = null;
		}
	}
	return userLocal;
}

const initialState = {
	userSignin: {
		userInfo: getInitialUserInfoCheck(),
	},
	checkInBatchInfo: {},
	document: {
		loading: false, ///used for network related stuff, such as uploading
		archivos: [],
		isEdittingNewFile: false, //used for dynamic forms, to determine is the user is editting a JustAddedFile and therefore block the 'add more' button
		filesData: [],
		currentFileId: undefined,
	},
	document_types: [],
	user_clients: {},
	signRequest: {
		loading: false,
		isSetup: false,
	},
	dossier: { loading: false, data: {} },
	myDocuments: { loading: false, documents: [] },
	dossierHistory: { loading: false, history: {} },
	pendingDossier: { loading: false, dossiers: [] },
	rejectDraft: { loading: true },
	approveDraft: { loading: true },
	rejectBeforeSignature: { loading: true },
};
const reducer = combineReducers({
	userSignin: userSigninReducer,
	checkInBatchInfo: checkInReducer,
	document: documentReducer,
	document_types: documentTypeReducer,
	user_clients: userClientsReducer,
	myDocuments: getMyDocumentsReducer,
	signRequest: signReducer,
	dossier: createDossierReducer,
	dossier: updateDossierReducer,
	dossier: getDossierReducer,
	dossierHistory: DossierHistoryReducer,
	pendingDossier: getPendingDossierReducer,
	rejectDraft: rejectDrafReducer,
	approveDraft: approveDrafReducer,
	rejectBeforeSignature: rejectBeforeSignatureReducer,
});
const composeEnhacer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	initialState,
	composeEnhacer(applyMiddleware(thunk))
);

export default store;
