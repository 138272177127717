/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// core components
import Admin from 'layouts/Admin.js';
import { Provider } from 'react-redux';

import 'assets/css/material-dashboard-react.css?v=1.10.0';
import store from './store';
import LoginScreen from 'views/LogIn/LogIn';

import VerificarDatosCliente from 'views/VerificarDatosCliente/VerificarDatosCliente';
import VerificarCliente from 'views/VerificarCliente/VerificarCliente';
import ComprobacionEstadoCliente from 'views/ComprobacionEstadoCliente/ComprobacionEstadoCliente';
import AccessUser from 'views/AccessUser/AccessUser';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';

const GlobalFontFamily =
	"'Avenir Next LT Pro Regular','Open Sans', 'sans-serif'";
const commonValues = {
	fontSize: '1.6rem',
	fontWeight: 600,
	lineHeight: '140%',
	fontFamily: undefined,
	'@media (max-width: 600px)': {
		fontSize: '1.5rem',
	},
};

const customTheme = createMuiTheme({
	palette: {
		primary: {
			//main: '#1f95dd' //lightblue - celeste
			main: '#3B6FB5', // '#f8f8f8', //'#f7765f' //lightblue - celeste #6389a1
			textColorWithMainBackground: '#ffffff',
		},
		secondary: {
			lighter: '#e4e9ec', ////Even lighter, generally used for margins in cards (refer to BaseCard)
			light: '#81878C', //lighter gray
			main: '#50595E', //gray - gris
			dark: '#003b71', //'#101f28', //almost black
		},
		hover: {
			backgroundColor: '#fafafa', //'#f8f9fa'
		},
	},
	typography: {
		h1: {
			fontSize: '1.5rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined, ///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '1.5rem',
			},
		},
		h2: {
			fontSize: '1.3rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined, ///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '1.2rem',
			},
		},
		h3: {
			fontSize: '1.1rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined,
			'@media (max-width: 600px)': {
				fontSize: '1rem',
			},
		},
		h4: {
			fontSize: '0.9rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined,
			'@media (max-width: 600px)': {
				fontSize: '0.8rem',
			},
		},
		h5: {
			fontSize: '0.8rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined,
		},
		h6: {
			fontSize: '0.7rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: undefined,
		},
		caption: {
			fontSize: '0.9rem',
			fontFamily: undefined,
			letterSpacing: '0.01111em',
			fontWeight: 'bold',
			color: 'black',
		},
		subtitle1: {
			///for elements such as the newsFooter on FeedsCard. Generally they are the smallest subtitles and are represented by an span tag
			fontSize: '1.25rem',
			fontFamily: undefined, ///because we are setting the default fontFamily on index.css
		},
		subtitle2: {
			///for elements such as the newsFooter on FeedsCard. Generally they are the smallest subtitles and are represented by an span tag
			fontSize: '1.05rem',
			fontFamily: undefined, ///because we are setting the default fontFamily on index.css
		},
		body1: {
			fontSize: '0.9rem',
			fontFamily: undefined,
		},
		button: {
			fontSize: '0.9rem',
			lineHeight: '140%',
			fontFamily: undefined,
			'@media (max-width: 600px)': {
				fontSize: '0.8rem',
			},
		},
	},
	customConstants: {
		pageBackgroundColor: '#f8f8f8', //"#f2f3f6",
		defaultBoxShadow:
			'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
		defaultBorder: '1px solid #ccc',
	},
	overrides: {
		MuiButton: {
			buttonPrimary: {
				backgroundColor: '#3F6FB5', // "#1866db",
				color: '#fff',
				marginTop: '20px',
				marginLeft: '15px',
			},
		},
	},
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={customTheme}>
				<Switch>
					<Route
						path='/cliente/verificar-datos/:dossier/:token'
						exact
						component={VerificarCliente}
					/>
					<Route
						path='/cliente/verificar-status/:dossier/:token'
						exact
						component={ComprobacionEstadoCliente}
					/>
					<Route
						path='/validate-user/:accessToken'
						component={AccessUser}
					/>
					<Route
						path='/forgot'
						component={ForgotPassword}
					/>
					<Route path='/login' component={LoginScreen} />
					<Route path='/' component={Admin} />
				</Switch>
			</ThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
