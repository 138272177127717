import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/components/pdfViewerStyle';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LoadingBox from 'components/LoadingBox/LoadingBox';
import GlifosDigitalArchiveViewer from 'components/GlifosDigitalArchiveViewer/GlifosDigitalArchiveViewer';

const useStyles = makeStyles(styles);

export default function PDFViewer({ url, signPosition = null, file }) {
	const classes = useStyles();
	console.log(file);
	return (
		<div
			className={classes.container}
			style={{
				height: file?.isWordFile ? '100%' : 'auto',
				minHeight: file?.isWordFile ? '500px' : 'auto',
			}}
		>
			{!url && (
				<div className={classes.noPDF}>
					<LoadingBox />
				</div>
			)}
			{url && (
				<GlifosDigitalArchiveViewer
					src={url}
					file={file}
					signPosition={signPosition}
				/>
			)}
		</div>
	);
}
