import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/glifos/mainLayoutStyle';
import logo from '../../assets/img/logo.svg';

import Copyright from '../../components/Copyright/Copyright';
import { useDispatch, useSelector } from 'react-redux';

import { signin } from '../../actions/userActions.js';
import MessageBox from '../../components/MessageBox/MessageBox';
import LoadingBox from '../../components/LoadingBox/LoadingBox';

const useStyles = makeStyles(styles);

function LoginScreen(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;
	const classes = useStyles();
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const dispatch = useDispatch();
	const submitHandler = (e) => {
		e.preventDefault();
		setEmailError('' === email.trim());
		setPasswordError('' === password.trim());
		dispatch(signin(email, password, remember));
	};

	return userInfo ? (
		(window.location.href = '/documento')
	) : (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.mainLogo}>
					<img src={logo} alt='Logo' className={classes.fitImage} />
				</div>
				<Typography component='h1' variant='h2'>
					Ingreso
				</Typography>
				{loading && <LoadingBox></LoadingBox>}

				<form
					className={classes.form}
					noValidate
					onSubmit={submitHandler}
				>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email'
						name='email'
						autoComplete='email'
						error={emailError}
						autoFocus
						className={classes.input}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						error={passwordError}
						autoComplete='current-password'
						className={classes.input}
						onChange={(e) => setPassword(e.target.value)}
					/>
					{/* <FormControlLabel
						control={
							<Checkbox
								value='remember'
								color='secondary'
								onChange={(e) => setRemember(e.target.value)}
							/>
						}
						label='Recordarme en este navegador'
					/> */}
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={classes.submit}
					>
						Sign In
					</Button>
					<div style={{ marginTop: '15px' }}>
						<Grid container>
							<Grid item xs>
								<Link
									href='/forgot'
									variant='body2'>
									¿Olvidó su contraseña?
								</Link>
							</Grid>
						</Grid>
					</div>

					{(emailError || passwordError) && (
						<MessageBox variant='danger' severity='error'>
							<Typography>
								{'Por favor ingrese sus credenciales'}
							</Typography>
						</MessageBox>
					)}
					{error && !(emailError || passwordError) && (
						<MessageBox variant='danger' severity='error'>
							{error}
						</MessageBox>
					)}
				</form>
			</div>
			{/* <Box mt={8}>
				<Copyright />
			</Box> */}
			{/* <PDFViewer url='https://raw.githubusercontent.com/pkp/pdfJsViewer/main/pdf.js/web/compressed.tracemonkey-pldi-09.pdf' /> */}
		</Container>
	);
}

export default LoginScreen;
