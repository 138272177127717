/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Description from '@material-ui/icons/Description';
import AddBox from '@material-ui/icons/AddBox';
import DashboardCliente from 'views/DashboardCliente/DashboardCliente.js';
import DocumentoCliente from 'views/DocumentoCliente/DocumentoCliente.js';
import FirmaCliente from 'views/FirmaCliente/FirmaCliente';
import BorradorCliente from 'views/BorradorCliente/BorradorCliente';
import VerificarDatosCliente from 'views/VerificarDatosCliente/VerificarDatosCliente';
import SignView from 'views/SignView/SignView';
import { VpnKey } from '@material-ui/icons';
import SignUploadView from 'views/SignUploadView/SignUploadView';

const dashboardRoutes = [
	{
		path: '/documento/:id/firma',
		name: 'Firma cliente',
		icon: AddBox,
		layout: '/admin',
		component: FirmaCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento/:id/borrador',
		name: 'Borrador cliente',
		icon: AddBox,
		layout: '/admin',
		component: BorradorCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento/:id',
		name: 'Documento cliente',
		icon: AddBox,
		layout: '/admin',
		component: DocumentoCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento',
		name: 'Documentos',
		icon: Description,
		layout: '/admin',
		component: DashboardCliente,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/firmas/nueva-firma',
		name: 'Firmas',
		icon: VpnKey,
		layout: '/admin',
		component: SignUploadView,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/firmas',
		name: 'Firmas',
		icon: VpnKey,
		layout: '/admin',
		component: SignView,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/revision-generales/:id',
		name: 'Documentos',
		icon: Description,
		layout: '/admin',
		component: VerificarDatosCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
];

export default dashboardRoutes;
