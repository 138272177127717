const ApiHostName =
	window.location.hostname.indexOf('firmaelectronicapp.com') >= 0
		? window.location.hostname
				.replace('firmaelectronicapp.com', 'api.firmaelectronicapp.com')
				.replace('.cliente', '')
		: window.location.hostname.indexOf('.local') >= 0 &&
		  window.location.hostname.indexOf('cliente.') >= 0
		? 'api.' + window.location.hostname.replace('cliente.', '')
		: window.location.hostname.indexOf('.local') >= 0
		? window.location.hostname
				.replace(':3000', '')
				.replace('dossier.local', 'api.dossier.local')
		: 'api.' + window.location.hostname.replace('cliente-', '');

const ApiURLs = {
	publicFilesURL: 'https://flujograma.app.local/v1/',
	baseURL: location.protocol + '//' + ApiHostName + '/v1/',
	UserLogin: {
		href: '/auth/authenticate',
		method: 'post',
		requires: ['username', 'password', 'remember'],
	},
	documentTypes: {
		href: '/api/tx/document-type',
		method: 'get',
	},
	userClients: {
		href: '/api/tx/list-clients',
		method: 'get',
	},
	UploadFile: {
		href: '/file/upload',
		method: 'post',
	},
	CreateDossier: {
		href: '/api/tx/create-dossier',
		method: 'post',
	},
	PendingDossier: {
		href: '/api/tx/my-pendings',
		method: 'get',
	},
	GetDossier: {
		href: '/api/tx/dossier-data',
		method: 'get',
		//requires: ['number'],
	},
	UpdateDossier: {
		href: '/api/tx/file-update',
		method: 'post',
	},
	DossierHistory: {
		href: '/api/tx/document-history',
		method: 'post',
	},
	ReadUserBatch: {
		href: '/glifos/api/checkin/batch',
		method: 'post',
	},
	dowloadFile: {
		href: '/glifos/file/download/',
		method: 'get',
	},
	getFileMetadata: {
		href: '/lot/lot-data',
		method: 'get',
	},
	signPrepare: {
		href: '/sign/prepare',
		method: 'post',
		requires: ['dossier_document_id'],
	},
	signFinish: {
		href: '/sign/finish',
		method: 'post',
		requires: [
			'request_id',
			'signature_id',
			'password',
			'x1',
			'y1',
			'width',
			'height',
			'page',
		],
	},
	getMyDocuments: {
		href: '/api/tx/my-documents',
		method: 'get',
	},
	rejectDraft: {
		href: '/api/tx/reject-draft',
		method: 'post',
	},
	approveDraft: {
		href: '/api/tx/approve-draft',
		method: 'post',
	},
	rejectBeforeSignature: {
		href: '/api/tx/reject-before-signature',
		method: 'post',
	},
	ValidateUser: {
		href: '/user/validate-user',
		method: 'post',
		requires: ['token', 'password'],
	},
	ForgotPassword: {
		href: '/user/forgot-password',
		method: 'post',
		requires: ['email', 'user_type'],
	},
	GetGenerales: {
		href: '/user/get-generales',
		method: 'post',
		requires: ['dossier'],
	},
	UpdateGenerales: {
		href: '/user/update-generales',
		method: 'post',
		requires: ['dossier', 'data'],
	},
	GetVerificationToken: {
		href: '/user/get-verification-token',
		method: 'get',
	},
	GetSignatures: {
		href: '/sign/signature-list',
		method: 'get',
	},
	UploadSignPrepare: {
		href: '/sign/upload-request',
		method: 'get',
	},
	UploadSignFinish: {
		href: '/sign/upload-finish',
		method: 'post',
		requires: ['uuid', 'name'],
	},
	GetEditable: {
		href: '/file/get-editable',
		method: 'post',
		requires: ['id'],
	},
};

export default ApiURLs;
