export const formatDate = (date) => {
	if (!date) return null;
	const dateObj = new Date(
		date.split('/').reverse().join('-') + 'T12:00:00Z'
	);
	return dateObj
		.toLocaleDateString('es-GT', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			timeZone: 'America/Guatemala',
		})
		.split('/')
		.reverse()
		.join('-');
};
