import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';

import { useDispatch, useSelector } from 'react-redux';

import ApiURLs from '../../config/ApiURLs.js';
import PDFViewer from 'components/PDFViewer/PDFViewer';
import { getDossier } from '../../actions/dossierActions';

import { useParams } from 'react-router-dom';
import MessageBox from 'components/MessageBox/MessageBox';
import { WORD_MIME_TYPES } from 'utils/constants';

const useStyles = makeStyles(styles);

export default function FirmaCliente(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { id } = useParams();

	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;

	const dossierState = useSelector((state) => state.dossier);
	const [dossier, setDossier] = useState(null);

	const [fileUrl, setFileUrl] = useState('');

	const goBack = () => {
		props.history.push('/documento');
	};

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		dispatch(getDossier(id));
	}, []);

	useEffect(() => {
		if (Object.keys(dossierState.data).length !== 0) {
			console.log(dossierState);
			const file_data =
				dossierState.data[0]?.relations?.sections?.[0]?.relations
					?.documents?.[0]?.relations?._File0?.[0]?.model;
			const temp = {
				dossierData:
					dossierState.data[0]?.relations?.sections[0]?.model,
				documentData:
					dossierState.data[0]?.relations?.sections[0]?.relations
						?.documents[0]?.model,
				file: {
					id: dossierState?.data[0]?.relations?.sections[0]?.relations
						?.documents[0]?.model?.file,
					isWordFile: WORD_MIME_TYPES.includes(file_data?.mime),
				},
			};
			console.log(temp);

			const dossierStatus = dossierState?.data[0]?.model?.status;
			if (dossierStatus === 2001) {
				setFileUrl(null);
				setDossier(temp);
			} else if (dossierStatus >= 2011 && dossierStatus <= 2069) {
				props.history.push(`/documento/${id}/borrador`);
			} else if (dossierStatus >= 2071 && dossierStatus <= 2130) {
				props.history.push(`/documento/${id}/firma`);
			} else {
				setFileUrl(
					ApiURLs.baseURL + 'file/get/' + temp.documentData?.file
				);
				setDossier(temp);
			} // else
		} // if
	}, [dossierState]);

	return (
		<div>
			{dossier && (
				<div>
					<Grid container className={classes.container}>
						<Grid item xs={6} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={1} className={classes.row}>
									<div
										className={classes.clickable}
										onClick={goBack}
									>
										<ArrowBackIcon />
									</div>
								</Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.id}
									>
										#{id}
									</Typography>
									<Typography variant='h3'>
										{dossier.dossierData?.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid container className={classes.container}>
								<Grid
									item
									xs={1}
									className={classes.row}
								></Grid>
								<Grid item xs={10} className={classes.leftRow}>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										Ver documento
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} className={classes.column}>
							<Grid container className={classes.container}>
								<Grid item xs={12} className={classes.rightRow}>
									<Typography
										variant='h3'
										className={classes.subHeadline}
									>
										Creador del documento
									</Typography>
								</Grid>
								<Grid item xs={12} className={classes.rightRow}>
									{/* <Typography variant="h3" className={classes.id}>
                      #{fakeCreator.id}
                    </Typography> */}
									<Typography variant='h3'>
										{dossier.dossierData?.created_by}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div
						className={classes.contentCard}
						style={{ minHeight: '500px' }}
					>
						{!fileUrl && (
							<MessageBox severity='info'>
								El documento aún no está disponible
							</MessageBox>
						)}
						{fileUrl && (
							<PDFViewer url={fileUrl} file={dossier.file} />
						)}
					</div>
				</div>
			)}
		</div>
	);
}
