import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import { withRouter } from 'react-router';
import { getMyDocuments } from 'actions/dossierActions';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import ApiURLs from '../../config/ApiURLs.js';
import { DOSSIER_STATES } from 'utils/constants.js';

const useStyles = makeStyles(styles);

var fakeColumns = [
	{
		name: 'Correlativo',
		align: 'center',
		resizable: true,
		minWidth: 80,
		//width: 120,
		flex: 1,
	},
	{
		name: 'Documento',
		align: 'left',
		resizable: true,
		minWidth: 150,
		//width: 450,
		flex: 3,
	},
	{
		name: 'Estado',
		align: 'center',
		resizable: true,
		minWidth: 100,
		//width: 150,
		flex: 2,
	},
];

function DashboardCliente(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [rows, setRows] = useState([]);

	const documents = useSelector((state) => state.myDocuments.documents);

	const openInNewTab = (url, fileAs) => {
		axios
			.get(url, {
				responseType: 'blob',
				headers: {
					Authorization: 'Bearer ' + userInfo.access_token, //the token is a variable which holds the token
				},
			})
			.then((res) => {
				fileDownload(res.data, fileAs);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatch(signout());
				}
			});
	};

	const visibilityActionClick = (id, action, doc, path = null) => {
		console.log('clicked on> ', id, action);
		if (action === 'edit' && path != null) props.history.push(path + id);
		else if (action != 'download') props.history.push('/documento/' + id);
		else
			openInNewTab(
				ApiURLs.baseURL + 'document/download/' + id,
				id + '.pdf'
			);
	};

	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;
	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		//dispatch(getPendingDossier());
		dispatch(getMyDocuments());
	}, []);

	useEffect(() => {
		if (documents) {
			// const dossiers = documents.map((item) => {
			//     return {
			//         dossiers: item.relations.relatedDossier[0].model.id
			//     }
			// });

			setRows(
				documents.map((item) => {
					//console.log("RELATED DOSSIER",item.relations.relatedDossier[0].relations.sections[0].relations.documents[0].model.file);
					return {
						id: item.relations.relatedDossier[0].model.id,
						documento: item.relations.relatedDossier[0].model.name,
						estado:
							getStatus(
								item.relations.relatedDossier[0].model.status
							)?.name || '',
						actions: getButtons(
							item.relations.relatedDossier[0].model.status,
							item.relations.relatedDossier[0]?.relations
								.sections[0]?.relations.documents[0].model.file
						),
						step:
							getStatus(
								item.relations.relatedDossier[0].model.status
							)?.step || 1,
					};
				})
			);
		}
	}, [documents]);

	const getStatus = (status) =>
		DOSSIER_STATES.find(
			(state) => state.minRange <= status && state.maxRange >= status
		);

	/* const getStatus = (status) => {
		if (status >= 2001 && status <= 2010) return 'Carga del borrador';
		if (status >= 2011 && status <= 2069) return 'Revision del borrador';
		if (status === 2070) return 'Borrador aprobado';
		if (status >= 2071 && status <= 2130) return 'Firma del documento';
		if (status >= 2131 && status <= 2200) return 'Autorización preventiva';
		if (status >= 2201 && status <= 2299) return 'Autorización Definitiva';
		if (status >= 2300 && status <= 2399) return 'Finalizado Archivado';
	}; */
	const getButtons = (status, file) => {
		if (status >= 1001 && status <= 2000)
			return [
				{
					action: 'edit',
					document: file,
					path: '/revision-generales/',
				},
				//				{ action: 'download', document: file },
			];
		if (status >= 2001 && status <= 2069)
			return [
				{ action: 'search', document: file },
				//				{ action: 'download', document: file },
			];
		if (status === 2070)
			return [
				{ action: 'edit', document: file },
				//				{ action: 'download', document: file },
			];
		if (status >= 2071 && status <= 2130)
			return [
				{ action: 'edit', document: file },
				//				{ action: 'download', document: file },
			];
		if (status >= 2131 && status <= 2200)
			return [
				{ action: 'edit', document: file },
				//				{ action: 'download', document: file },
			];
		if (status >= 2201 && status <= 2299)
			return [
				{ action: 'edit', document: file },
				//				{ action: 'download', document: file },
			];
		if (status >= 2300 && status <= 2399)
			return [
				{ action: 'edit', document: file },
				//				{ action: 'download', document: file },
			];
	};

	const handleCellClick = (params) => {
		console.log('clicked on> ', params?.row?.col4 == 2);
		if (params.field === 'actions') return;
		if (params?.row?.col4 == 2)
			props.history.push('/revision-generales/' + params.id);
		else props.history.push('/documento/' + params.id);
	};

	return (
		<div style={{ height: '100%' }}>
			<ContentTable
				title='Documentos'
				pseudoRows={rows}
				columnDefinitions={fakeColumns}
				renderActionsHeader
				visibilityHandler={visibilityActionClick}
				onCellClick={handleCellClick}
			/>
		</div>
	);
}

export default withRouter(DashboardCliente);
