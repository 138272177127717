import {
	CREATE_DOSSIER_REQUEST,
	CREATE_DOSSIER_REQUEST_SUCCESS,
	CREATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_REQUEST,
	DOSSIER_REQUEST_SUCCESS,
	DOSSIER_REQUEST_FAIL,
	PENDING_DOSSIER_REQUEST,
	PENDING_DOSSIER_REQUEST_SUCCESS,
	PENDING_DOSSIER_REQUEST_FAIL,
	UPDATE_DOSSIER_REQUEST,
	UPDATE_DOSSIER_REQUEST_SUCCESS,
	UPDATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_HISTORY_REQUEST,
	DOSSIER_HISTORY_REQUEST_SUCCESS,
	DOSSIER_HISTORY_REQUEST_FAIL,
	DOSSIER_COMMENT_REQUEST,
	DOSSIER_COMMENT_REQUEST_SUCCESS,
	DOSSIER_COMMENT_REQUEST_FAIL,
	MY_DOCUMENTS_REQUEST,
	MY_DOCUMENTS_REQUEST_SUCCESS,
	MY_DOCUMENTS_REQUEST_ERROR,
	REJECT_DRAFT_REQUEST,
	REJECT_DRAFT_REQUEST_SUCCESS,
	REJECT_DRAFT_REQUEST_ERROR,
	APPROVE_DRAFT_REQUEST,
	APPROVE_DRAFT_REQUEST_SUCCESS,
	APPROVE_DRAFT_REQUEST_ERROR,
	REJECT_BEFORE_SIGNATURE_REQUEST,
	REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS,
	REJECT_BEFORE_SIGNATURE_REQUEST_ERROR
} from '../constants/dossierConstants';

export const createDossierReducer = (
	state = { loading: false, data: {} },
	action
) => {
	switch (action.type) {
		case CREATE_DOSSIER_REQUEST: {
			return { ...state, loading: true };
		}
		case CREATE_DOSSIER_REQUEST_SUCCESS: {
			return { ...state, loading: false, data: action.payload };
		}
		case CREATE_DOSSIER_REQUEST_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const getPendingDossierReducer = (
	state = { loading: false, dossiers: {} },
	action
) => {
	switch (action.type) {
		case PENDING_DOSSIER_REQUEST: {
			return { ...state, loading: true };
		}
		case PENDING_DOSSIER_REQUEST_SUCCESS: {
			return { ...state, loading: false, dossiers: action.payload };
		}
		case PENDING_DOSSIER_REQUEST_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const getDossierReducer = (
	state = { loading: false, data: {} },
	action
) => {
	switch (action.type) {
		case DOSSIER_REQUEST: {
			return { ...state, loading: true };
		}
		case DOSSIER_REQUEST_SUCCESS: {
			return { ...state, loading: false, data: action.payload };
		}
		case DOSSIER_REQUEST_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const updateDossierReducer = (
	state = { loading: false, data: {} },
	action
) => {
	switch (action.type) {
		case UPDATE_DOSSIER_REQUEST: {
			return { ...state, loading: true };
		}
		case UPDATE_DOSSIER_REQUEST_SUCCESS: {
			return { ...state, loading: false, data: action.payload };
		}
		case UPDATE_DOSSIER_REQUEST_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const DossierHistoryReducer = (
	state = { loading: false, history: {} },
	action
) => {
	switch (action.type) {
		case DOSSIER_HISTORY_REQUEST: {
			return { ...state, loading: true };
		}
		case DOSSIER_HISTORY_REQUEST_SUCCESS: {
			return { ...state, loading: false, history: action.payload };
		}
		case DOSSIER_HISTORY_REQUEST_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const getMyDocumentsReducer = (
	state = { loading: false, documents: [] },
	action
) => {
	switch (action.type) {
		case MY_DOCUMENTS_REQUEST: {
			return { ...state, loading: true };
		}
		case MY_DOCUMENTS_REQUEST_SUCCESS: {
			return { ...state, loading: false, documents: action.payload };
		}
		case MY_DOCUMENTS_REQUEST_ERROR: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const rejectDrafReducer = (
	state = { loading: false },
	action
) => {
	switch (action.type) {
		case REJECT_DRAFT_REQUEST: {
			return { ...state, loading: true };
		}
		case REJECT_DRAFT_REQUEST_SUCCESS: {
			return { ...state, loading: false };
		}
		case REJECT_DRAFT_REQUEST_ERROR: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const approveDrafReducer = (
	state = { loading: false },
	action
) => {
	switch (action.type) {
		case APPROVE_DRAFT_REQUEST: {
			return { ...state, loading: true };
		}
		case APPROVE_DRAFT_REQUEST_SUCCESS: {
			return { ...state, loading: false };
		}
		case APPROVE_DRAFT_REQUEST_ERROR: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

export const rejectBeforeSignatureReducer = (
	state = { loading: false },
	action
) => {
	switch (action.type) {
		case REJECT_BEFORE_SIGNATURE_REQUEST: {
			return { ...state, loading: true };
		}
		case REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS: {
			return { ...state, loading: false };
		}
		case REJECT_BEFORE_SIGNATURE_REQUEST_ERROR: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};
