import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/verificarDatosClienteStyle';
// import logo from '../../assets/img/logo.svg';
import Axios from 'services/Api';
import FileUploader from 'components/FileUploader/FileUploader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate } from 'utils/formatters';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function VerificarDatosCliente(props) {
	const classes = useStyles();
	const { id: dossier } = useParams();
	const [userData, setUserData] = useState({
		isLoading: false,
		isError: false,
		errorMessage: '',
		payload: null,
	});
	const [formData, setFormData] = useState({
		nombres: '',
		apellidoPaterno: '',
		apellidoMaterno: '',
		sexo: '',
		nacionalidad: '',
		edad: '',
		fechaNacimiento: '',
		lugarNacimiento: '',
		estado: '',
		claveElector: '',
		numeroPersonal: '',
		tipoDocumento: '',
		fechaEmision: '',
		numeroDocumento: '',
		direccion: '',
		calle: '',
		colonia: '',
		ciudadEstado: '',
	});
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const handleUpdate = () => {
		setShowConfirmModal(true);
	};
	const sendData = async () => {
		try {
			setUserData((prevData) => ({ ...prevData, isLoading: true }));
			const { data } = await Axios.UpdateGenerales({
				dossier,
				data: formData,
			});
			if (Boolean(data?.error)) {
				throw new Error(data?.message);
			}
			setUserData((prevData) => ({ ...prevData, isLoading: false }));
			props.history.push('/documento');
		} catch (e) {
			console.log(e);
			setUserData((prevData) => ({ ...prevData, isLoading: false }));
			setShowConfirmModal(false);
		}
	};

	useEffect(() => {
		const getInitialData = async () => {
			try {
				setUserData({
					isLoading: true,
					isError: false,
					errorMessage: '',
					payload: null,
				});
				const { data } = await Axios.GetGenerales({ dossier });
				if (Boolean(data?.error)) {
					throw new Error(data?.message);
				}
				setUserData({
					isLoading: false,
					isError: false,
					errorMessage: '',
					payload: data,
				});
				const { result } = data;
				const sexo =
					result.find(
						(element) =>
							element?.type?.toLowerCase() == 'sex' ||
							element?.name?.toLowerCase() == 'data.sex'
					)?.value || '';
				const fecha_nacimiento =
					result.find(
						(element) =>
							element?.type?.toLowerCase() == 'date of birth' ||
							element?.name?.toLowerCase() ==
								'fecha de nacimiento'
					)?.value || '';
				const fecha_emision =
					result.find(
						(element) =>
							element?.type?.toLowerCase() == 'date of issue' ||
							element?.name?.toLowerCase() == 'fecha de emisión'
					)?.value || '';
				const tipoDocumento =
					result.find(
						(element) =>
							element?.type?.toLowerCase() ==
								'document class code' ||
							element?.name?.toLowerCase() ==
								'código de tipo del documento'
					)?.value || '';

				setFormData((prevData) => ({
					...prevData,

					nombres:
						result.find(
							(element) =>
								element?.type?.toLowerCase() == 'given names' ||
								element?.name?.toLowerCase() == 'nombre'
						)?.value || '',
					apellidoPaterno:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'father surname' ||
								element?.name?.toLowerCase() ==
									'apellido paterno'
						)?.value || '',
					apellidoMaterno:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'mother surname' ||
								element?.name?.toLowerCase() ==
									'apellido materno'
						)?.value || '',
					sexo:
						sexo == 'M'
							? 'Masculino'
							: sexo == 'F'
							? 'Femenino'
							: sexo == 'H'
							? 'Hombre'
							: '',
					nacionalidad:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'nactionality' ||
								element?.name?.toLowerCase() == 'nacionalidad'
						)?.value || '',
					edad:
						result.find(
							(element) =>
								element?.type?.toLowerCase() == 'age' ||
								element?.name?.toLowerCase() == 'edad'
						)?.value || '',
					fechaNacimiento: formatDate(fecha_nacimiento),
					lugarNacimiento:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'place of birth' ||
								element?.name?.toLowerCase() ==
									'lugar de nacimiento'
						)?.value || '',
					estado:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'address state' ||
								element?.name?.toLowerCase() == 'estado'
						)?.value || '',
					claveElector:
						result.find(
							(element) =>
								element?.type?.toLowerCase() == 'voter key' ||
								element?.name?.toLowerCase() ==
									'clave de elector'
						)?.value || '',
					numeroPersonal:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'document number' ||
								element?.name?.toLowerCase() ==
									'número de documento'
						)?.value || '',
					tipoDocumento:
						tipoDocumento == 'P'
							? 'Pasaporte'
							: tipoDocumento == 'ID'
							? 'INE'
							: '',
					fechaEmision: formatDate(fecha_emision),
					numeroDocumento:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'personal number' ||
								element?.name?.toLowerCase() == 'número persona'
						)?.value || '',
					direccion:
						result.find(
							(element) =>
								element?.type?.toLowerCase() == 'address' ||
								element?.name?.toLowerCase() == 'dirección'
						)?.value || '',
					calle:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'address street' ||
								element?.name?.toLowerCase() == 'calle'
						)?.value || '',
					colonia:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'address suburb' ||
								element?.name?.toLowerCase() == 'colonia'
						)?.value || '',
					ciudadEstado:
						result.find(
							(element) =>
								element?.type?.toLowerCase() ==
									'address city' ||
								element?.name?.toLowerCase() == 'ciudad/estado'
						)?.value || '',
				}));
			} catch (e) {
				console.log(e);
				setUserData({
					isLoading: false,
					isError: true,
					errorMessage: e.message,
					payload: null,
				});
			}
		};
		getInitialData();
	}, []);
	return (
		<div className={classes.wrapper}>
			{/* <div className={classes.header}></div> */}
			<div className={classes.content}>
				<div className={classes.contentCard}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant='h3' className={classes.title}>
								Verificación de Generales
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid
									item
									xs={12}
									className={classes.sectionLeft}
								>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Nombres
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.nombres}
												name='nombres'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Apellido Paterno
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.apellidoPaterno}
												name='apellidoPaterno'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Apellido Materno
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.apellidoMaterno}
												name='apellidoMaterno'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Sexo
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.sexo}
												name='sexo'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Nacionalidad
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.nacionalidad}
												name='nacionalidad'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Edad
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.edad}
												name='edad'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Fecha de Nacimiento
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												type='date'
												value={formData.fechaNacimiento}
												name='fechaNacimiento'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Lugar de Nacimiento
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.lugarNacimiento}
												name='lugarNacimiento'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Estado
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.estado}
												name='estado'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Clave de Elector
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.claveElector}
												name='claveElector'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Número Personal
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.numeroPersonal}
												name='numeroPersonal'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Tipo de Documento
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.tipoDocumento}
												name='tipoDocumento'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Fecha de Emisión
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												type='date'
												value={formData.fechaEmision}
												name='fechaEmision'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Número de Documento
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.numeroDocumento}
												name='numeroDocumento'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Dirección
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.direccion}
												name='direccion'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Calle
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.calle}
												name='calle'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Colonia
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.colonia}
												name='colonia'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={4}>
											<Typography variant='body1'>
												Ciudad/Estado
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												value={formData.ciudadEstado}
												name='ciudadEstado'
												onChange={handleChange}
											/>
										</Grid>
									</div>
									<div className={classes.buttonSpace}>
										<Button
											variant='contained'
											color='primary'
											className={classes.buttonPrimary}
											onClick={handleUpdate}
											disabled={userData.isLoading}
										>
											Actualizar datos
										</Button>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
			<Dialog
				open={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				aria-labelledby='responsive-dialog-title'
			>
				<DialogTitle id='responsive-dialog-title' disableTypography>
					<Typography variant='h3'>
						{'¿Desea confirmar sus generales?'}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Esta es la información que será utilizada por la notaría
						para llevar a cabo el proceso correspondiente. Si los
						datos mencionados son correctos y estás de acuerdo con
						que sean utilizados de esta manera, te pedimos que hagas
						clic en el botón "Aceptar" a continuación para proceder
						con el trámite en base a esta información.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={() => setShowConfirmModal(false)}
						color='primary'
					>
						Cancelar
					</Button>
					<Button onClick={sendData} color='primary' autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
