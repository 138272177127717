import React, { useEffect } from 'react';
import { DataGrid, esES } from '@material-ui/data-grid';
import { Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-react/components/contentTableStyle';
import EntitledContainer from 'components/EntitledContainer/EntitledContainer';

const useStyles = makeStyles(styles);

export default function ContentTable(props) {
	const classes = useStyles();
	const defaultColumnWidth = 170;
	const defaultColumnMinWidth = 100;
	const {
		columnDefinitions,
		pseudoRows,
		renderActionsHeader,
		title,
		visibilityHandler,
		onCellClick = () => {},
		onRowClick = () => {},
		density = 'compact',
	} = props;

	const actionsRenderFunctions = (params) => {
		if (params.field !== 'actions')
			return <Typography variant='caption'>{params.value}</Typography>;
		else {
			return (
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					{' '}
					{/*TODO: Agregar Listener a cada icono, y pasar params.id que hace referencia al id del row :DD*/}
					{params.value?.map((element, index) => {
						const actionHandler = visibilityHandler;

						return (
							<Icon
								key={index}
								style={{ cursor: 'pointer' }}
								onClick={() =>
									actionHandler(
										params.id,
										params.value[index].action,
										params.value[index].document,
										params.value[index]?.path ?? null
									)
								}
							>
								{element.action}
							</Icon>
						);
					})}
				</div>
			);
		}
	};

	const handleElementRemoval = (indexToRemove) => {
		console.log('Rows before removal', rows);
		console.log('About to remove', indexToRemove);

		//setRows(rows.filter(row => row.id != indexToRemove));
		var newRows = rows.filter((row) => row.id != indexToRemove);
		setTimeout(() => setRows(newRows), 1000); //because of race conditions, maybe can try lowering it up to 1sec?
	};

	const generateColumns = () => {
		var generatedColumns = columnDefinitions.map((element, index) => {
			return {
				field: 'col' + index,
				headerName: element.name ? element.name : element,
				//width: element.width ? element.width : defaultColumnWidth,
				minWidth: element.minWidth
					? element.minWidth
					: defaultColumnMinWidth,
				headerAlign: element.align ? element.align : 'left',
				align: element.align ? element.align : 'left',
				flex: element.flex ? element.flex : 1,
				filterable: true,
			};
		});
		console.log('GeneratedColumns', generatedColumns);
		if (renderActionsHeader)
			generatedColumns.push({
				field: 'actions',
				headerName: 'Acciones',
				//flex: 1,
				width: defaultColumnWidth,
				headerAlign: 'center',
				align: 'center',
				sortable: false,
				renderCell: actionsRenderFunctions,
				filterable: false,
			});
		return generatedColumns;
	};

	const generateRows = () => {
		var generatedRows = pseudoRows.map((element, index) => {
			//console.log('element ' + index + ' es', element);
			var retElement = {};
			retElement.id = element.id ? element.id : index;
			var values = Object.values(element);

			values.forEach((element, index) => {
				if (Array.isArray(element)) retElement.actions = element;
				else retElement['col' + index] = element;
			});
			return retElement;
		});
		//console.log('GeneratedRows', generatedRows);
		setRows(generatedRows);
	};
	const [rows, setRows] = React.useState([]);

	useEffect(() => {
		generateRows();
	}, [pseudoRows]);

	return (
		<EntitledContainer title={title ? title : null}>
			{/* TODO: ver ese problema con el height */}
			<div style={{ height: 400, width: '100%' }}>
				<DataGrid
					columns={generateColumns()}
					rows={rows}
					disableColumnSelector
					className={classes.datagrid}
					localeText={esES.props.MuiDataGrid.localeText}
					onCellClick={onCellClick}
					onRowClick={onRowClick}
					density={density}
					responsiveLayout='stacked'
				/>
			</div>
		</EntitledContainer>
	);
}
