import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Axios from 'services/Api';
import { CircularProgress } from '@material-ui/core';
import { WebVerification } from 'vdid-sdk-web';
// import logo from '../../assets/img/logo.svg';

const styles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100vh',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '50%',
		height: '40vh',
		backgroundColor: '#ffffff',
		border: '2px solid #000000',
		flexWrap: 'wrap',
		padding: '2rem',

		[theme.breakpoints.down('md')]: {
			width: '60%',
			height: '50vh',
		},
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '60vh',
		},
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			height: '80vh',
		},
	},

	alertError: {
		color: 'red',
		border: '1px solid red',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffebee',
		borderRadius: '5px',
		width: '100%',
		alignSelf: 'center',
		position: 'relative',
	},
	closeIconAlert: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		justifyContent: 'center',
		width: '20px',
		'&:hover': {
			color: 'black',
			borderRadius: '50%',
			aspectRatio: '1/1',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
});

const useStyles = makeStyles(styles);

const statusText = {
	WaitingData: {
		title: 'Información en proceso de verificación',
		message: `Su información ha sido enviada para ser verificada, se le notificará por correo cuando haya finalizado el proceso.
					O puede ingresar a esta pantalla para consultar el estado del proceso en cualquier momento.`,
	},
	Completed: {
		title: 'Su identidad ha sido verificada',
		message: `Su identidad ha sido verificada exitosamente, puede continuar con el proceso de validación y firma de documentos.`,
	},
	CheckedError: {
		title: 'No se puedo Verificar su identidad',
		message:
			'No se puedo verificar su identidad con la información proporcionada previamente, por favor vuelva a realizar el proceso con las siguientes observaciones:',
	},
};

export default function ComprobacionEstadoCliente(props) {
	const classes = useStyles();
	const { token, dossier } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState({
		error: false,
		message: '',
	});
	const [userStatus, setUserStatus] = useState('WaitingData');
	const [userResults, setUserResults] = useState({});

	const ValidateStatus = async () => {
		if (+userResults?.status === -50) {
			try {
				const { data } = await Axios.get(
					`/user/new-token/${dossier}/${token}`
				);
				if (Boolean(data?.error)) {
					throw new Error(
						data?.message
							? data?.message
							: data?.error_log
							? data.error_log
							: 'Ocurrio un error, por favor intentelo más tarde'
					);
				}
				setIsLoading(false);
				window.location.href = `/cliente/verificar-datos/${dossier}/${data?.token}`;
			} catch (error) {
				//console.log(error)
				setIsError({
					error: true,
					message: error.message,
				});
				setIsLoading(false);
			}
		} else if (+userResults?.status === 0 || +userResults?.status === 52) {
			window.location.href =
				userResults?.link ||
				`/cliente/verificar-datos/${dossier}/${token}`;
		} else if (+userResults?.status >= 100) {
			window.location.href = `/login`;
		} else {
			verificarStatus();
		}
	};

	const verificarStatus = useCallback(async () => {
		setIsLoading(true);
		try {
			const { data } = await Axios.get(
				`/user/verificar-status/${dossier}/${token}`
			);
			if (Boolean(data?.error)) {
				throw new Error(
					data?.message
						? data?.message
						: data?.error_log
						? data.error_log
						: 'Ocurrio un error, por favor intentelo más tarde'
				);
			}
			setUserResults(data);
			/*if (data?.user_status) {
			if (data.user_status === 'Checked') {
					const { data: userResults } = await Axios.get(
						`/user/get-results/${dossier}/${token}`,
						{
							headers: {
								Authorization: 'Bearer ' + token,
							},
						}
					);
					if (Boolean(userResults?.error)) {
						throw new Error(
							userResults?.message ??
								'Ocurrio un error, por favor intentelo más tarde'
						);
					}
					setUserResults(userResults);
					if (Boolean(userResults?.success)) {
						setUserStatus('Completed');
					} else {
						setUserStatus('CheckedError');
					}
				} else {
					setUserStatus(data.user_status);
				}
			} */
			setUserStatus('CheckedError');
		} catch (error) {
			setIsError({
				error: true,
				message: error.message,
			});
		} finally {
			setIsLoading(false);
		}
	}, [token]);

	useEffect(() => {
		verificarStatus();
	}, [verificarStatus]);

	return (
		<main className={classes.mainContainer}>
			<section className={classes.cardContainer}>
				<Typography variant='h2' className={classes.title}>
					{userResults?.text_to_show?.title ||
						'Verificación de datos'}
				</Typography>
				<div>
					<p>
						{userResults?.text_to_show?.description ||
							'Información en proceso de verificación.'}
						{+userResults?.status === -50 && (
							<ul>
								{Object.values(
									userResults?.text_to_show?.errors || {}
								).map((doc, index) => (
									<li key={index}>{doc}</li>
								))}
							</ul>
						)}
					</p>
					{isError.error && (
						<div className={classes.alertError}>
							{isError.message ||
								'Ocurrio un error, por favor intentelo más tarde'}
							<span
								className={classes.closeIconAlert}
								onClick={() => {
									setIsError({
										error: false,
										message: '',
									});
								}}
							>
								X
							</span>
						</div>
					)}
				</div>
				<Button
					variant='contained'
					color='primary'
					className={classes.buttonPrimary}
					onClick={ValidateStatus}
					disabled={isLoading || isError.error}
					endIcon={isLoading ? <CircularProgress size={20} /> : null}
				>
					{+userResults?.status === -50
						? 'Generar nueva verificación'
						: +userResults?.status === 52 ||
						  +userResults?.status === 0
						? 'Ir a la verificación'
						: +userResults?.status >= 100
						? 'Aceptar'
						: 'Comprobar Estado'}
				</Button>
				{/* <Grid item xs={12}>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} className={classes.sectionLeft}>
							<div className={classes.buttonSpace}>
								<Button
									variant='contained'
									color='primary'
									className={classes.buttonPrimary}
								>
									Verificar Datos
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid> */}
			</section>
		</main>
	);
}
