import {
	CREATE_DOSSIER_REQUEST,
	CREATE_DOSSIER_REQUEST_SUCCESS,
	CREATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_REQUEST,
	DOSSIER_REQUEST_SUCCESS,
	DOSSIER_REQUEST_FAIL,
	PENDING_DOSSIER_REQUEST,
	PENDING_DOSSIER_REQUEST_SUCCESS,
	PENDING_DOSSIER_REQUEST_FAIL,
	UPDATE_DOSSIER_REQUEST,
	UPDATE_DOSSIER_REQUEST_SUCCESS,
	UPDATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_HISTORY_REQUEST,
	DOSSIER_HISTORY_REQUEST_SUCCESS,
	DOSSIER_HISTORY_REQUEST_FAIL,
	DOSSIER_COMMENT_REQUEST,
	DOSSIER_COMMENT_REQUEST_SUCCESS,
	DOSSIER_COMMENT_REQUEST_FAIL,
	MY_DOCUMENTS_REQUEST,
	MY_DOCUMENTS_REQUEST_SUCCESS,
	MY_DOCUMENTS_REQUEST_ERROR,
	REJECT_DRAFT_REQUEST,
	REJECT_DRAFT_REQUEST_SUCCESS,
	REJECT_DRAFT_REQUEST_ERROR,
	APPROVE_DRAFT_REQUEST,
	APPROVE_DRAFT_REQUEST_SUCCESS,
	APPROVE_DRAFT_REQUEST_ERROR,
	REJECT_BEFORE_SIGNATURE_REQUEST,
	REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS,
	REJECT_BEFORE_SIGNATURE_REQUEST_ERROR,
} from '../constants/dossierConstants';
import Swal from 'sweetalert2';

import Axios from '../services/Api.js';

export const createDossier =
	(title, description, document_type, file, RFC, INE, CURP, signers) =>
	async (dispatch) => {
		dispatch({ type: CREATE_DOSSIER_REQUEST, loading: true });
		console.log(signers);
		const body = {
			data: {
				title,
				description,
				document_type,
				file,
				type: 2,
				document_type,
				requirements: { RFC, INE, CURP },
				signers: { client: signers.map((signer) => signer.id) },
			},
		};
		try {
			const { data } = await Axios.CreateDossier(body);
			console.log(data);

			if (data.result) {
				dispatch({
					type: CREATE_DOSSIER_REQUEST_SUCCESS,
					payload: data.create_or_update.state,
				});
				Swal.fire('Expediente creado!', '', 'success');
			} else {
				dispatch({
					type: CREATE_DOSSIER_REQUEST_FAIL,
					error: 'Hubo un error al crear el expediente',
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: CREATE_DOSSIER_REQUEST_FAIL,
				error:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const getDossier = (number) => async (dispatch) => {
	dispatch({ type: DOSSIER_REQUEST, loading: true });

	try {
		const { data } = await Axios.GetDossier({ params: { number } });
		console.log(data);

		if (data.result) {
			dispatch({
				type: DOSSIER_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al crear el expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: CREATE_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getPendingDossier = () => async (dispatch) => {
	dispatch({ type: PENDING_DOSSIER_REQUEST, loading: true });

	try {
		const { data } = await Axios.PendingDossier();
		console.log(data);

		if (data.result) {
			dispatch({
				type: PENDING_DOSSIER_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: CREATE_DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al obtener los expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: PENDING_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const updateDossier = (dossier, file) => async (dispatch) => {
	dispatch({ type: UPDATE_DOSSIER_REQUEST, loading: true });

	const body = {
		data: {
			file,
			dossier,
		},
	};
	try {
		const { data } = await Axios.UpdateDossier(body);
		console.log(data);

		if (data.result) {
			dispatch({
				type: UPDATE_DOSSIER_REQUEST_SUCCESS,
				payload: data.create_or_update.state,
			});
			Swal.fire('Expediente actualizado!', '', 'success');
		} else {
			dispatch({
				type: UPDATE_DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al crear el expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: UPDATE_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getDossierHistory = (dossier, document) => async (dispatch) => {
	dispatch({ type: DOSSIER_HISTORY_REQUEST, loading: true });

	const body = {
		data: {
			document,
			dossier,
		},
	};
	try {
		const { data } = await Axios.DossierHistory(body);
		console.log(data);

		if (data.result) {
			dispatch({
				type: DOSSIER_HISTORY_REQUEST_SUCCESS,
				payload: data.create_or_update.state,
			});
			Swal.fire('Expediente actualizado!', '', 'success');
		} else {
			dispatch({
				type: DOSSIER_HISTORY_REQUEST_FAIL,
				error: 'Hubo un error al crear el expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOSSIER_HISTORY_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getMyDocuments = () => async (dispatch) => {
	dispatch({ type: MY_DOCUMENTS_REQUEST, loading: true });

	try {
		const { data } = await Axios.getMyDocuments();
		console.log(data);

		if (data.result) {
			dispatch({
				type: MY_DOCUMENTS_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: MY_DOCUMENTS_REQUEST_ERROR,
				error: 'Hubo un error al obtener los expedientes',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: MY_DOCUMENTS_REQUEST_ERROR,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const rejectDraft =
	(dossier_id, comment, callback) => async (dispatch) => {
		dispatch({ type: REJECT_DRAFT_REQUEST, loading: true });

		try {
			const body = {
				data: {
					dossier: dossier_id,
					comment: comment,
				},
			};
			const { data } = await Axios.rejectDraft(body);

			if (data.result == true) {
				dispatch({
					type: REJECT_DRAFT_REQUEST_SUCCESS,
					payload: data,
				});
				Swal.fire(
					'Comentario enviado',
					'Se le ha enviado su comentario al notario para que pueda revisarlo.',
					'success'
				).then(() => callback());
			} else {
				dispatch({
					type: REJECT_DRAFT_REQUEST_ERROR,
					error: 'Hubo un error al rechazar borrador',
				});
				Swal.fire(
					'Hubo un error al rechazar borrador',
					'',
					'error'
				).then(() => callback());
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: REJECT_DRAFT_REQUEST_ERROR,
				error:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const approveDraft = (dossier_id) => async (dispatch) => {
	dispatch({ type: APPROVE_DRAFT_REQUEST, loading: true });

	try {
		const body = {
			data: {
				dossier: dossier_id,
			},
		};
		const { data } = await Axios.approveDraft(body);
		//console.log(data);

		if (data.result == true) {
			dispatch({
				type: APPROVE_DRAFT_REQUEST_SUCCESS,
				payload: data,
			});
			Swal.fire('Borrador aprobado', '', 'success').then(()=>window.location.href = "/documento");
		} else {
			dispatch({
				type: APPROVE_DRAFT_REQUEST_ERROR,
				error: 'Hubo un error al aprobar borrador',
			});
			Swal.fire('Hubo un error al aprobar borrador', '', 'error');
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: APPROVE_DRAFT_REQUEST_ERROR,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const rejectBeforeSignature =
	(dossier_id, comment) => async (dispatch) => {
		dispatch({ type: REJECT_BEFORE_SIGNATURE_REQUEST, loading: true });

		try {
			const body = {
				data: {
					dossier: dossier_id,
					comment: comment,
				},
			};
			const { data } = await Axios.rejectBeforeSignature(body);
			console.log(data);

			if (data.result == true) {
				dispatch({
					type: REJECT_BEFORE_SIGNATURE_REQUEST_SUCCESS,
					payload: data,
				});
				Swal.fire('Documento rechazado', '', 'success');
			} else {
				dispatch({
					type: REJECT_BEFORE_SIGNATURE_REQUEST_ERROR,
					error: 'Hubo un error al rechazar documento',
				});
				Swal.fire('Hubo un error al rechazar documento', '', 'error');
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: REJECT_BEFORE_SIGNATURE_REQUEST_ERROR,
				error:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};
